/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

// polyfill setPrototypeOf:
(function () {
  Object.setPrototypeOf = Object.setPrototypeOf || ({ __proto__: [] } instanceof Array ? setProtoOf : mixinProperties);

  function setProtoOf(obj, proto) {
    obj.__proto__ = proto;
    return obj;
  }

  function mixinProperties(obj, proto) {
    for (const prop in proto) {
      if (!obj.hasOwnProperty(prop)) {
        obj[prop] = proto[prop];
      }
    }
    return obj;
  }
})();

// polyfill element.hidden
(function (global) {
  'use strict';

  // var notInBrowser = !global.HTMLElement || !HTMLElement.prototype;
  var alreadyDefined = ('hidden' in HTMLElement.prototype);
  var notPossibleToImplement = typeof Object.defineProperty === 'undefined';

  // if (notInBrowser || alreadyDefined || notPossibleToImplement) {
  //   return;
  // }

  Object.defineProperty(HTMLElement.prototype, 'hidden', {
    get: function () {
      return this.hasAttribute('hidden');
    },
    set: function (value) {
      if (value) {
        this.setAttribute('hidden', '');
      } else {
        this.removeAttribute('hidden');
      }

      return value;
    }
  });
})(typeof window === 'undefined' ? this : window);


/**
 * IE9, IE10 and IE11 requires all of the following polyfills.
 * **/

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
import 'classlist.js'  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
import 'core-js/es6/reflect'
import 'intersection-observer'

/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
import 'core-js/es7/reflect'
import 'core-js/es7/array'

import flexibility from 'flexibility';
flexibility(document.documentElement);

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import objectFitImages from 'object-fit-images';
objectFitImages();


/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 **/
// import 'web-animations-js'  // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */

// (window as any).__Zone_disable_requestAnimationFrame = true // disable patch requestAnimationFrame
// (window as any).__Zone_disable_on_property = true // disable patch onProperty such as onclick
// (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove'] // disable patch specified eventNames

/*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 */
// (window as any).__Zone_enable_cross_context_check = true

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js/dist/zone' // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */
